import React, { useState } from "react"
import DatePicker from "react-datepicker"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "react-datepicker/dist/react-datepicker-cssmodules.css"
import contactStyles from "../css/contact.module.css"

const Contact = () => {
  const [eventDate, setEventDate] = useState(new Date())

  return (
    <Layout>
      <SEO title="Contact" />
      <div className={contactStyles.container}>
        <form
          className={contactStyles.contactForm}
          name="contact"
          method="post"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className={contactStyles.row}>
            <label>
              <span>
                First Name <span className={contactStyles.required}>*</span>
              </span>
              <input type="text" name="first_name" required />
            </label>
            <label>
              <span>
                Last Name <span className={contactStyles.required}>*</span>
              </span>
              <input type="text" name="last_name" required />
            </label>
          </div>
          <div className={contactStyles.row}>
            <label>
              <span>
                Email <span className={contactStyles.required}>*</span>
              </span>
              <input type="email" name="email" required />
            </label>
          </div>
          <div className={contactStyles.row}>
            <label>
              <span>
                Phone <span className={contactStyles.required}>*</span>
              </span>
              <input type="phone" name="phone" required />
            </label>
          </div>
          <div className={contactStyles.row}>
            <label>
              <span>
                Event Type <span className={contactStyles.required}>*</span>
              </span>
              <select name="event_type" required>
                <option value="wedding">Wedding</option>
                <option value="single service">Single Service</option>
                <option value="photo session">Photo Session</option>
                <option value="makeup lesson">Makeup Lesson</option>
                <option value="other">Other</option>
              </select>
            </label>
            <label htmlFor="event_date">
              <span>
                Event Date <span className={contactStyles.required}>*</span>
              </span>
              <DatePicker
                name="event_date"
                selected={eventDate}
                onChange={date => setEventDate(date)}
                dateFormat="MMMM d, yyyy"
                required={true}
              />
            </label>
          </div>
          <div className={contactStyles.row}>
            <label>
              <span>
                How many people in your party will be needing a makeup
                application? <span className={contactStyles.required}>*</span>
              </span>
              <input
                type="text"
                name="number_of_party_needing_makeup"
                required
              />
            </label>
          </div>
          <div className={contactStyles.row}>
            <label>
              <span>
                What time does your party need to be ready?{" "}
                <span className={contactStyles.required}>*</span>
              </span>
              <input type="text" name="party_ready_time" required />
            </label>
          </div>
          <div className={contactStyles.row}>
            <label>
              <span>
                Location of service (Where are you getting ready?){" "}
                <span className={contactStyles.required}>*</span>
              </span>
              <input type="text" name="location_of_service" required />
            </label>
          </div>
          <div className={contactStyles.row}>
            <label>
              How did you hear about me?
              <input type="text" name="how_did_you_hear_about_me" />
            </label>
          </div>
          <div className={contactStyles.row}>
            <label>
              <span>
                Message <span className={contactStyles.required}>*</span>
              </span>
              <textarea name="message" required></textarea>
            </label>
          </div>
          <div className={contactStyles.row}>
            <button type="submit" className={contactStyles.submitBtn}>
              Send
            </button>
          </div>
        </form>
        <div className={contactStyles.description}>
          <h1>Questions?</h1>
          <p>
            I want to help! Please feel free to email me with any concerns. You
            can reach me at{" "}
            <a href="mailto:jachosek@gmail.com">jachosek@gmail.com</a>.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
